<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-8">
                        <div class="font-weight-bold body-1">
                            Editar Remisión (Cotización)
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <v-form ref="form" @submit.prevent="save">
                            <sale-order-form ref="SaleOrderForm" :values="form" @save="save" :serverErrors="serverErrors" :edit="true" @actionModal="(v) => {acceptModal()}"></sale-order-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="8" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button data-cy="cancel-btn" :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <secondary-button data-cy="draft-btn" :props="draftBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button data-cy="accept-btn" :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../../helpers/Utils';
import SaleOrderForm from "./Form.vue";
export default {
    components: {
        'sale-order-form': SaleOrderForm,
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            serverErrors: {},
            form: {
                warehouse_id: '',
                client_id: '',
                project_id: '',
                quotation_id: '',
                operation_id: null,
                date: "",
                observations:'',
                type: '', //draft, complete
                comments: '', //Comentario < a 30 caracteres only draft
                services: [
                    // {
                    //     "service_id": "almpa01-almacenaje-de-tarima-por-mes-o-fraccion-carga-descarga-y-control-de-inventario",
                    //     "quantity": 6,
                    //     "amount": 30,
                    //     "reference": "referencia 1"
                    // },
                    // {
                    //     "service_id": "cross40pa-cross-dock-contenedor-de-40-pies-mercancia-palletizada-incluye-carga-y-descarga",
                    //     "quantity": 5,
                    //     "amount": 23,
                    //     "reference": "referencia 2"
                    // }
                ]
                    
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "SaleOrders",
                block:false,
                click: () => {}
            },
            draftBtnData: {
                text: "Guardar borrador",
                icon: "mdi-content-save-outline",
               
                block:false,
                click: this.openModal
            },
            saveBtnData: {
                text: "Guardar cambios",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
        openModal()
        {
            this.$refs.SaleOrderForm.openDraftModal();
        },
        acceptModal(v)
        {
            this.save(v,"draft");
        },
       
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.draftBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.draftBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.saleOrder.get(this.id)
			.then((response) => {
                console.log(response);
                
                this.form = {
                    ...this.form,
                    warehouse_id: response.data.data.warehouse_id,
                    client_id: response.data.data.client_id,
                    project_id: response.data.data.operation_id,
                    quotation_id: response.data.data.quotation_id,
                    type: response.data.data.type,
                    comments: response.data.data.comments??"",
                    operation_id: response.data.data.operation_id,
                    date: response.data.data.date,
                    observations: response.data.data.observations,
                    services: response.data.services.map(ser => {return {...ser, amount: ser.amount.toString().replace(",","")}})
                }

                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        save(_, saleType = "complete"){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                let form = this.prepareRequest(this.form, saleType);
                console.log(form);
                if(saleType == "complete")
                    this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$refs.SaleOrderForm.acceptModal.loading = true;
                this.$api.saleOrder.update(this.id, form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
                        this.$router.push({name: 'SaleOrders'});
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("errors" in error.response.data)){
                                    if(error.response.data.errors instanceof Object){
                                        Object.keys(error.response.data.errors).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.errors;
                                            error.response.data.errors[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.errors)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                         try {
                            this.$refs.SaleOrderForm.acceptModal.loading = false;
                            this.$refs.SaleOrderForm.acceptModal.visible = false;
                        } catch (error) {
                            console.log(error);
                        }
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario incompleto`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request, type)
        {
            let data = JSON.parse(JSON.stringify(request));
            
            for (let i = 0; i < data.services.length; i++) {
                delete data.services[i].name;
                delete data.services[i].actions;
                delete data.services[i].parameters;
                
            }

            data.type = type;

            return data;
        }
    },
}
</script>

<style>

</style>